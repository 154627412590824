@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Playfair+Display&display=swap");

.App {
  text-align: center;
  height: 93vh;
  font-family: "Roboto Condensed", sans-serif;
}

body {
  /* background: url("sea.jpg") fixed; */
  background-position: 0 0 0 0;
  background-color: #050505;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #050505;
  margin-top: 1rem;
  height: 19vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  font-size: 1em;
  color: white;
  padding: 1rem 17%;
  position: fixed;
  top: -3%;
  z-index: 10000;
}

.App-header h1 {
  margin: 0;
  font-size: calc(14px + 2vmin);
}

#initiallist li a#logo {
  width: 3rem;
  height: 3rem;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-image: url("./assets/viruspic-removebg.png");
  background-size: cover;
  /* filter: grayscale(100%); */
  /* background-position: 10px; */
  /* padding: 1.3rem 1.45rem 1.3rem 1.3rem; */
  margin-top: -1rem;
  background-color: #050505e0;
}

#initiallist li a#logo:hover {
  filter: brightness(1.8);
}

.routcont2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin: 0 auto;
  padding-left: 3%;
}

.container {
  display: flex;
  min-height: 90%;
  flex-direction: row;
  justify-content: center;
}

.dummy-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: url("./assets/viruspic-removebg.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  filter: blur(5px);
}

.usefulLinks {
  margin: 1rem 1rem;
  min-width: 300px;
  list-style-type: none;
  font-weight: bold;
  background-color: #fafafa;
  border: 1px dotted black;
  border-radius: 15px;
  opacity: 0.8;
  max-height: 690px;
}

p {
  color: #dcb6f1;
}

#stats-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#years-labels {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  list-style-type: none;
  position: absolute;
  padding: 1rem;
  background-color: #bfb7ee;
  color: #875475;
  font-size: large;
  width: 7rem;
  margin-top: -5rem;
  z-index: 1;
  border-radius: 5px;
  font-weight: bold;
  padding: 0.75rem 1rem 0.75rem 0.75rem;
  cursor: pointer;
}

#home-charts {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 60vh;
  padding: 0 3rem;
  margin: -1% auto;
  background-color: rgba(92, 84, 135, 0.5);
  color: rgb(240, 236, 236);
  border-radius: 5px;
  z-index: 1;
}

.chart {
  display: none !important;
}

.chart.selected {
  display: block !important;
  /* width: 30vw !important;
  height: 53vh !important; */
}
.diagram-but {
  color: white;
  margin-top: 1.5rem;
  border: 1px solid grey;
  background-color: #373444;
  border-radius: 5px;
  height: 12%;
}

.diagram-but:hover {
  color: black;
  background-color: #bfb7ee;
}
.diagram-but.prev {
  position: absolute;
  left: 3%;
}
.diagram-but.next {
  position: absolute;
  right: 3%;
}

#countdown-outbreak {
  position: fixed;
  top: 0;
  left: 0;
  right: -20px;
  font-weight: bold;
  background-color: #875475;
  border: 1px dotted black;
  opacity: 0.8;
  padding: 1rem;
  color: black;
  min-width: 100%;
  height: 7vh;
  z-index: 6;
  text-align: right;
}

#countdown-outbreak span {
  position: absolute;
  text-align: left;
  width: 250vw;
  top: 35%;
  left: 100vw;
  animation: moveleft 25s linear infinite;
}

#countdown-outbreak span.not_animated {
  position: absolute;
  top: 30%;
  left: 33.5%;
  right: 33%;
  margin: 0 auto;
  font-size: larger;
  text-align: center;
  animation-play-state: paused;
  width: 33vw;
  color: #f097d1;
  z-index: 1000;
  animation: 1s vanish infinite;
}

@keyframes moveleft {
  from {
    transform: translateX(0vw);
  }
  to {
    transform: translateX(-250vw);
  }
}

@keyframes vanish {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.usefulLinks li {
  list-style-type: none;
  padding: 1rem;
  text-align: center;
}

.linksList {
  padding-left: 0;
}

.routcont {
  position: absolute;
  top: 30vh;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  margin: 0 auto;
  z-index: 5;
}

#overmenurow {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

p#advices {
  line-height: 3rem;
  font-size: 1.15em;
  color: black;
  margin-top: 0.3rem;
  margin-left: 0.5em;
}

#underline {
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 0.125em;
}

span.advice-highlights {
  font-size: 1em;
  font-weight: bold;
  color: white;
}

/*MENU BUTTON*/

.openbtn {
  position: absolute;
  top: 48%;
  left: 45;
  right: 45;
  margin-left: -1%;
  border-radius: 5px;
  font-size: 1.8em;
  font-weight: bolder;
  background-color: rgba(191, 183, 238, 0);
  color: rgb(191, 183, 238);
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
}

.openbtn:hover {
  border: 1px solid rgb(191, 183, 238);
}

.diagram-but.prev {
  position: absolute;
  left: 2%;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  font-size: xx-large;
  color: #dcb6f1;
}

.diagram-but.next {
  position: absolute;
  right: 2%;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  font-size: xx-large;
  color: #dcb6f1;
}

.diagram-but.prev:hover,
.diagram-but.next:hover {
  border: 1px solid #dcb6f1;
}

/*NAVBAR*/

#initiallist {
  padding: 0;
  position: absolute;
  bottom: 3%;
  margin: 1.65rem 0 0 0;
  height: 10vh;
  align-items: center;
  justify-content: center;
}

ul#initiallist li a:hover {
  background: rgba(92, 84, 135, 0.8);
  color: rgb(191, 183, 238) !important;
}

ul#initiallist li a.active {
  background: rgb(191, 183, 238);
  color: #050505 !important;
}

ul#initiallist li {
  margin-right: 1rem;
}

ul#initiallist li a {
  text-decoration: none;
  padding: 0.5rem;
  font-size: 1em;
  border-radius: 10px;
  color: rgb(191, 183, 238);
  width: 100%;
  height: 100%;
}

@keyframes moveMenuDown {
  0% {
    opacity: 0;
    top: 0%;
  }
  100% {
    opacity: 1;
    top: 51%;
  }
}

.usefulLinks {
  max-height: 285px;
  position: relative;
}

/*BREADCRUMBS*/

#breadbrumbs {
  margin-top: 1.25rem;
  display: flex;
  justify-content: space-evenly;
  font-weight: bold;
  font-size: 1.1em;
  color: black;
}

/*COUNTRY SEARCH*/

input {
  padding: 0.5rem;
  width: 300px;
}

button {
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 10%;
}

.country_search {
  position: absolute;
  top: 15vh;
  left: 30%;
  right: 35%;
  background: rgba(92, 84, 135, 0.8);
  border-radius: 5px;
  padding: 1rem;
  min-width: 40vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: -3rem;
}

.country_search h6 {
  color: #bfb7ee;
}

.forma {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.6rem;
}

#countryinput {
  border-radius: 10px;
  margin: 0 auto;
}

#search-buttons-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#countrysearchbtn {
  background-color: #ffffff;
  font-size: 1em;
  padding: 0.75rem;
  border: white;
  color: black;
  margin-left: 0 auto;
}

#countrysearchbtn:hover {
  background-color: grey;
  color: white;
}

#commentbelow {
  font-weight: bold;
  padding-top: 1rem;
  color: #c46ea6;
}

#star {
  font-weight: bold;
  color: white;
}

.notvalidcountry {
  background-color: "#fafafa";
  margin-bottom: 5.5rem;
  margin-top: 1.5rem;
  border-radius: 5px;
  border: 1px dotted white;
  padding: 1rem;
  font-weight: bold;
  color: black;
}

.ContList li {
  list-style-type: none;
  margin-left: 0;
  padding: 0.75rem;
  color: black;
  font-weight: bold;
}

#recommendations_link {
  margin-top: 1rem;
}

/*INITIAL WARNING*/

#warn {
  background-color: rgba(57, 58, 58, 0.6);
  padding: 0.5rem;
  margin-right: -1.3%;
  width: 20%;
  font-size: 0.5%;
  font-weight: bold;
  color: rgba(240, 243, 243, 0.6);
  border-radius: 5px;
  z-index: inherit;
}

/*INFO CARDS*/

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 5rem;
  color: black;
}

.info h4 {
  color: black;
}

.globalinfo,
.countryinfo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 5rem;
  border-radius: 10px;
  color: black;
}

.country_title {
  color: #0a0a0a;
}

.country_data {
  position: absolute;
  top: 0;
  left: -10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: black;
  height: 50%;
}

.backToTop {
  color: white;
  margin-top: 1.5rem;
  border: 1px solid grey;
  background-color: #373444;
  border-radius: 5px;
}

.backToTop:hover {
  color: black;
  background-color: rgb(191, 183, 238);
}

.card {
  margin: 1rem;
  padding: 1rem;
  border: 1px dotted black;
  max-width: 60%;
  border-radius: 15px;
  opacity: 0.8;
  background-color: #f0f0f0;
  color: black;
  opacity: 0.7;
  /* position: relative; */
}

#travelinfo_card {
  margin-top: 20vh;
  margin-left: 10vw;
  margin-right: 10vw;
  background-color: "#fafafa";
  color: white;
  line-height: 2em;
  padding: 1rem;
  min-height: 30vh;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

#recommendations_link {
  color: darkblue;
  font-weight: bold;
}

/*CARD IMAGES*/

.country_image {
  position: relative;
  top: -20;
  left: 0;
  height: 80vh;
  width: 120%;
  filter: blur(1px);
}

.global_image {
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: -1;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.useful_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.2;
  max-height: 100%;
  border-radius: 10px;
}

/*TABLE TOP 10 COUNTRIES AND US*/

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

.countryinfo th {
  background-color: #3f3a5ce6;
  color: #f1efef;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #875475;
}

tr:nth-child(odd) {
  color: white;
  background-color: black;
  opacity: 0.7;
}

/*World map options*/

svg.map-img {
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  border-radius: 10px;
  min-height: 400px;
  background: #796eb1e4;
}

svg.map-img:hover {
  cursor: pointer;
}

.map-selected {
  fill: #e3da37;
}

.map-unselected {
  fill: #3c5257;
}

.map-unselected:hover {
  fill: #416d77;
}

/*Map Tooltip options*/
.class {
  position: relative;
  z-index: 0;
}

#displayText {
  width: 40%;
  font-size: small;
  margin: 0 auto;
  background-color: #3e0f75e6;
  color: rgb(123, 44, 136);
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  z-index: 1000;
}

.animated {
  animation-name: popup;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes popup {
  0% {
    transform: scale(1.15, 1.15);
  }
  25% {
    transform: scale(1.1, 1.1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  75% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1.4, 1.4);
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #050505;
  color: #ffffff;
  text-align: center;
  padding: 1rem;
  font-size: 1em;
  z-index: 10;
}
@media screen and (min-width: 200px) and (max-width: 400px) {
  .openbtn {
    font-size: 1em;
  }

  td {
    font-size: 0.7em;
    padding: 0.2em;
  }

  th {
    font-size: xx-small;
  }

  #warn {
    font-size: small;
  }

  /* Outbreak msg */

  #countdown-outbreak {
    height: 8vh;
  }
  #countdown-outbreak span {
    width: 600% !important;
    font-size: small;
  }

  #countdown-outbreak span.not_animated {
    width: 100% !important;
    font-size: small;
    margin-left: -35%;
    height: 100%;
    line-height: 2.2;
  }

  /*Menu*/

  .App-header {
    padding: 0.5rem;
  }

  .navbar_div {
    width: 20%;
  }
  ul#initiallist {
    display: flex;
    position: absolute;
    display: flex;
    /* top: 51%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    font-weight: bold;
    color: black;
    justify-content: space-evenly;
    margin-top: 0;
    margin-left: -15px;
    width: 102%;
    /* margin-left: 40%; */
    z-index: 11000;
    height: 250%;
    background-color: rgba(0, 0, 0, 1);
  }

  ul#initiallist li {
    font-size: 0.75em;
    width: 100%;
  }

  ul#initiallist li a#logo {
    margin-left: 5%;
  }

  /* Home diagrams */

  #years-labels {
    width: 5rem;
    height: 2.5rem;
    position: relative;
    font-size: medium;
    padding: 10px 0 0 10px;
    margin: -4rem auto 2rem auto;
  }

  #years-labels option {
    width: 100%;
    height: 100%;
  }

  #home-charts {
    width: 98vw !important;
  }

  /*Country search*/

  .country_search {
    min-height: 120px;
    margin-top: -3rem;
  }

  .forma {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .country_search h6 {
    color: #bfb7ee;
    font-size: 0.85em;
  }

  #countrysearchbtn {
    margin: 0.6em auto 0.3em auto;
    padding: 0.3em;
    max-width: 50%;
    border-radius: 5px;
    border: 1px dotted grey;
  }

  #countryinput {
    border-radius: 10px;
    margin: 0.6em auto;
    padding: 0.1em;
    width: 80%;
  }

  /*Conuntry table*/

  #headings-country-table {
    font-size: small;
  }

  /*Map*/

  /* .displayText {
    max-height: 53%;
    margin-top: -65px;
  }

  .displayText a {
    font-size: 0.7em;
    margin: 0.25em;
  } */

  svg.map-img {
    margin-bottom: 2rem;
    margin-top: -45px;
  }
}

@media screen and (min-width: 400px) and (max-width: 500px) {
  .App-header {
    max-height: 150px;
  }

  .container {
    flex-direction: column;
  }
  .adv {
    text-align: center;
  }
  .openbtn {
    max-width: 80px;
  }

  .forma {
    display: flex;
    flex-direction: column;
  }

  #warn {
    font-size: small;
  }

  /*Navbar */
  #navbar_div {
    font-size: 0.8em;
    width: 100%;
  }

  /*USEFUL LINKS*/

  .usefulLinks {
    max-height: 500px;
    width: 100%;
    margin-right: 1rem;
  }

  .linksList {
    display: flex;
    justify-content: center;
  }

  div.card {
    width: 300px;
    margin: 1rem;
  }

  #countdown-outbreak {
    width: 103vw;
  }
  /* OUTBREAK MSG */
  #countdown-outbreak span {
    width: 500vw !important;
  }
  #countdown-outbreak span.not_animated {
    width: 51vw !important;
    left: 26vw;
    font-size: medium;
  }

  /*NAVLIST*/

  #initiallist {
    display: flex;
    position: absolute;
    top: 51%;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    font-weight: bold;
    color: black;
    justify-content: space-evenly;
    margin-top: 0;
    width: 102%;
    z-index: 11000;
    height: 250%;
    background-color: rgba(0, 0, 0, 1);
  }

  ul#initiallist li {
    padding: 0;
    width: 100%;
    height: 20%;
    border: 1px dotted rgb(51, 47, 75);
    color: white;
    margin: 0 auto;
  }

  /* Home diagrams */
  #home-charts {
    width: 95vw !important;
  }

  /*COUNTRY SEARCH*/

  #countrymaincard {
    margin-bottom: 5rem;
  }

  #countrysearchbtn {
    margin: 0.6em auto 0.3em auto;
    padding: 0.3em;
    max-width: 50%;
    border-radius: 5px;
    border: 1px dotted grey;
  }

  #countryinput {
    border-radius: 10px;
    margin: 0.6em auto;
    padding: 0.1em;
    width: 80%;
  }

  /*TABLE*/

  th,
  td {
    font-size: 0.7em;
    padding: 0.2em;
  }
  th {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  /*MAP*/

  svg.map-img {
    max-width: 400px;
    margin-top: -3em;
  }

  /* MAP TOOLTIP*/

  /* .displayText {
    max-height: 50%;
    margin-top: -75px;
  }

  .displayText p {
    font-size: 0.95em;
    font-weight: bold;
    margin: 3px;
  } */
}
@media screen and (min-width: 501px) and (max-width: 600px) {
  .openbtn {
    font-size: medium;
  }
  #initiallist {
    display: flex;
    position: absolute;
    top: 51%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    font-weight: bold;
    color: black;
    justify-content: space-evenly;
    margin-top: 0;
    margin-left: 40%;
    z-index: 11000;
    height: 280%;
    background-color: rgba(0, 0, 0, 1);
  }

  ul#initiallist li {
    padding: 0;
    width: 100%;
    border: 1px dotted rgb(51, 47, 75);
    color: white;
    margin: 0 auto;
  }

  #warn {
    font-size: small;
  }

  th,
  td {
    font-size: 0.8em;
    padding: 0.4em;
  }

  th {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }

  #countdown-outbreak {
    width: 103vw;
  }
  #countdown-outbreak span.not_animated {
    width: 51vw;
    left: 26vw;
    font-size: large;
  }

  #countryinput {
    border-radius: 10px;
    margin: 0.2em auto;
    padding: 0.1em;
    width: 80%;
  }

  #countrysearchbtn {
    margin: 0.6em 0.6em;
    padding: 0.3em;
    max-width: 50%;
    border-radius: 5px;
    border: 1px dotted grey;
  }

  svg.map-img {
    max-width: 500px;
    margin-top: -2em;
  }
}

@media screen and (min-width: 601px) and (max-width: 1100px) {
  #initiallist {
    display: flex;
    position: absolute;
    right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    font-weight: bold;
    color: black;
    justify-content: space-evenly;
    margin-top: 0;
    margin-left: 40%;
    z-index: 11000;
    height: 220%;
    background-color: rgba(0, 0, 0, 1);
  }

  ul#initiallist li {
    padding: 0;
    width: 100%;
    border: 1px dotted rgb(51, 47, 75);
    color: white;
    margin: 0 auto;
  }

  th,
  td {
    font-size: 0.8em;
    padding: 0.4em;
  }

  #countdown-outbreak span.not_animated {
    width: 50%;
    left: 25%;
  }

  #countryinput {
    border-radius: 10px;
    margin: 0.2em auto;
    padding: 0.3em;
    width: 80%;
  }

  #countrysearchbtn {
    margin: 0.6em 0.6em;
    padding: 0.5em;
    max-width: 50%;
    border-radius: 5px;
    border: 1px dotted grey;
  }

  /* .displayText {
    max-height: 43%;
    margin-top: -65px;
  }

  .displayText a {
    font-size: medium;
    margin: 0.25em;
  } */
}

@media screen and (max-width: 1100px) {
  .container {
    flex-direction: column;
  }

  .usefulLinks {
    max-height: 500px;
    width: 95%;
    margin-right: 1rem;
  }

  .linksList {
    display: flex;
    justify-content: center;
  }

  .card {
    width: 500px;
    margin: 1rem;
  }

  ul#initiallist {
    list-style-type: none;
    font-weight: bold;
    justify-content: center;
    animation: 2s moveMenuDown forwards;
  }

  ul#initiallist li {
    padding: 1rem;
    height: 25%;
    line-height: 2.5;
  }

  #countdown-outbreak span {
    position: absolute;
    text-align: left;
    width: 450vw;
    top: 35%;
    left: 100vw;
    animation: moveleft 20s linear infinite;
  }
}

@media screen and (min-width: 1101px) {
  .card {
    width: 800px;
  }

  /*.routcont {margin-left:5rem;}*/

  ul#initiallist {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    list-style-type: none;
    font-weight: bold;
    justify-content: left;
  }

  ul#initiallist li {
    padding: 1rem;
    color: black;
  }

  th,
  td {
    font-size: 0.95em;
    padding: 0.5em;
  }

  #countryruta {
    border-radius: 10px;
    margin: 0.2em auto;
    padding: 0.3em;
    max-width: 50%;
  }

  #countrysearchbtn {
    margin: 0.6em 0.6em;
    padding: 0.5em;
    max-width: 50%;
    border-radius: 5px;
    border: 1px dotted grey;
  }

  /* .displayText {
    max-height: 43%;
    margin-top: -80px;
  } */
}
